<template>
    <div :id="blokId(blok)" class="text-center align-middle flex flex-col">
        <div v-if="richContentText" v-html="richContentText" />
        <ButtonContainer :buttons="blok.buttons" />
    </div>
</template>

<script setup lang="ts">
import { richTextResolver } from '@storyblok/richtext'
import ButtonContainer from '~/components/Buttons/ButtonContainer.vue'
import { blokId } from '~/utils/functions'

const { render } = richTextResolver()

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
})

const richContentText = computed(() => render(props.blok.content))
</script>
